<template>
  <div>
    <div>
      <b-form>
        <div class="row">
          <b-col>
            <span class="header-tablepage"> ID: </span>
            <span class="header-tablepage">{{ detail.prefix_id }}</span>
          </b-col>
          <b-col>
            <b-form-select
              v-model="detail.status"
              value-field="id"
              text-field="name"
              :options="status_options"
            ></b-form-select
          ></b-col>
        </div>
        <div class="title-tabs mt-3 content-between">
          <div>Booking Detail</div>
          <div>
            {{ $moment(detail.created_time).format("DD/MM/YYYY HH:mm:ss") }}
          </div>
        </div>
        <b-container class="no-gutters bg-white" v-if="!isLoading">
          <div class="py-3">
            <div>
              <div class="row row-gap-5">
                <FieldDisplay
                  :value="detail.username"
                  label="Customer Name-Surname"
                  :routeLink="`/report/customer/${detail.user_guid}?tab=5`"
                  :hasedit="true"
                  @handleClick="handleChangeName"
                  class="col-md-3"
                />

                <FieldDisplay
                  :value="detail.telephone"
                  :routeLink="`/report/customer/${detail.user_guid}?tab=5`"
                  label="Telephone"
                  class="col-md-3"
                />
                <FieldDisplay
                  :value="detail.email"
                  label="Email"
                  :routeLink="`/report/customer/${detail.user_guid}?tab=5`"
                  class="col-md-3"
                />
                <div class="break-normal"></div>
                <InputText
                  :isRequired="true"
                  textFloat="Firstname (Booking)"
                  placeholder=""
                  v-model="detail.firstname_answer"
                  :v="$v.detail.firstname_answer"
                  type="text"
                  :disabled="disabledPoint"
                  name="firstname_answer"
                  class="col-md-3"
                />
                <InputText
                  :isRequired="true"
                  textFloat="Lastname (Booking)"
                  placeholder=""
                  v-model="detail.lastname_answer"
                  :v="$v.detail.lastname_answer"
                  type="text"
                  :disabled="disabledPoint"
                  name="lastname_answer"
                  class="col-md-3"
                />
                <div class="break-normal"></div>
                <div class="col-md-3">
                  <AutoComplete
                    v-model="detail.sale_branch_id"
                    :showValue="detail.sale_branch"
                    id="branch-list"
                    dataListID="branch-arr-list"
                    label="Branch"
                    placeholder="Please select branch"
                    @change="handleChangeBranch()"
                    :v="$v.detail.sale_branch_id"
                    :isRequired="true"
                    :optionsList="branchList"
                  />
                </div>
                <div class="col-md-3">
                  <AutoComplete
                    v-model="detail.sale_user_guid"
                    :showValue="detail.sale"
                    id="sale-list"
                    dataListID="sale-arr-list"
                    label="Staff ID"
                    valueField="user_guid"
                    :v="$v.detail.sale_user_guid"
                    :isRequired="true"
                    placeholder="Please select staff"
                    :disabled="staffList.length == 0"
                    :optionsList="staffList"
                  />
                </div>

                <FieldDateTime
                  name="Booking Date Time"
                  v-model="detail.booking_date"
                  :value="detail.booking_date"
                  :isRequired="true"
                  :statusCampaign="statusCampaign"
                  class="col-md-3"
                />

                <InputText
                  textFloat="Additinal Point"
                  placeholder="0"
                  v-model="detail.point"
                  inputmode="numeric"
                  type="number"
                  :disabled="disabledPoint"
                  name="numberScore"
                  class="col-md-3"
                />
              </div>
              <hr />
              <div
                class="row"
                v-for="(field, index) in detail.description"
                :key="'T1' + index + field.field_id"
                :set="(profile_field = field.field_profile_type_id)"
              >
                <div class="col-md-6">
                  <!-- {{ field }} -->
                  <div v-if="field.field_type_id == 1" class="mb-3">
                    <FieldTextInput
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>

                  <div v-else-if="field.field_type_id == 2">
                    <FieldCheckbox
                      :field="field"
                      :statusCampaign="statusCampaign"
                      :index="index"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 3">
                    <FieldRadio
                      :field="field"
                      :statusCampaign="statusCampaign"
                      :index="index"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 4" class="mb-3">
                    <FieldDropdown
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 5" class="mb-3">
                    <FieldDateTime
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 6" class="mb -3">
                    <FieldBranch
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 7" class="mb-3">
                    <FieldDate
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>
                  <div v-else-if="field.field_type_id == 8">
                    <label
                      >{{ field.name }}
                      <span class="text-danger" v-if="field.required">*</span>
                    </label>
                    <UploadImage
                      :disabled="
                        statusCampaign != 0 && field.field_profile_type_id != 0
                      "
                      :indexData="index"
                      @updateImageList="updateImageList"
                      :dataList="field.value"
                      @deleteImagesList="deleteImgList"
                    />
                    <a :href="field.value" target="_blank">
                      <span>
                        {{ field.name }}
                        <b-icon icon="search" variant="grey"></b-icon>
                      </span>
                    </a>
                  </div>
                  <div v-else-if="field.field_type_id == 12">
                    <FieldTextAreaInput
                      :field="field"
                      :statusCampaign="statusCampaign"
                    />
                  </div>
                </div>
                <!-- <div class="col-md-6"></div> -->
                <div class="break-normal"></div>
              </div>
            </div>
          </div>
        </b-container>
        <div v-else><OtherLoading /></div>
        <div class="title-tabs mt-3 log-title">
          Note

          <b-button
            @click="toggleAddNote()"
            variant="save"
            :disabled="!detail.sale_user_guid"
            >Add Note 1</b-button
          >
        </div>
        <b-container class="no-gutters bg-white">
          <div class="py-3" v-if="isEditNote">
            <TextEditorsTiny
              textFloat="Description"
              :rows="4"
              :value="detail.note"
              :name="'description_' + 1"
              placeholder="Type something..."
              @onDataChange="(val) => (detail.note = val)"
              isRequired
            />
            <b-row>
              <b-col
                ><b-button
                  @click="isEditNote = false"
                  variant="cancel"
                  class="btn-width btn-secondary"
                  >Cancel</b-button
                ></b-col
              >
              <b-col class="text-right"
                ><b-button @click="handleAddNote()" variant="save"
                  >Save Draft</b-button
                ></b-col
              >
            </b-row>
          </div>
          <div v-if="!isLoading">
            <div v-if="detail.answer_log">
              <div
                class="py-3 container-log"
                v-if="detail.answer_log.length > 0"
              >
                <div
                  class="panel-log"
                  v-for="(log, index) of detail.answer_log"
                  :key="'log_' + log.id + index"
                >
                  <div class="writer-log">
                    <div class="text-black">
                      <b>{{ log.sale_name }}</b>
                    </div>
                    <div>
                      {{ $moment(log.created_time).fromNow() }}
                      {{
                        $moment(log.created_time).format(
                          "(DD/MM/YYYY HH:mm:ss)"
                        )
                      }}
                    </div>
                  </div>
                  <div v-if="log.note.includes('Change')">- {{ log.note }}</div>
                  <div v-else>
                    <div>- Add Note 1</div>

                    <div class="p-2">
                      <div v-html="log.note" class="container-note"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center p-4">No Information Found</div>
          </div>
        </b-container>
      </b-form>
      <div class="footer-action">
        <b-col>
          <router-link :to="`/booking`">
            <b-button class="btn-cancel btn-width">Back</b-button>
          </router-link>
        </b-col>
        <b-col class="text-right">
          <b-button
            class="main-color btn-save"
            @click="submitCampaign(1)"
            :disabled="statusCampaign == 2"
          >
            {{ statusCampaign == 1 ? "Save" : "Approve" }}
          </b-button>
        </b-col>
      </div>
    </div>
    <b-modal
      v-model="modalMessageShow"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      footer-class="justify-content-start"
      header-class="bg-purple1"
    >
      <template #modal-header>
        <div class="w-100">
          <strong class="title-modal white-color">Search Customer</strong>
          <b-icon
            icon="x-circle-fill"
            class="float-right pointer white-color"
            @click="modalMessageShow = false"
          ></b-icon>
        </div>
      </template>
      <div>
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Customer Name"
            v-model="filterCustomer.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
        <b-table
          responsive
          striped
          hover
          :fields="fieldCustomer"
          :items="itemsCustomer"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="{ item }">
            <b-button
              @click.prevent="handleChangeCustomer(item)"
              :disabled="item.user_guid == detail.user_guid"
              :class="item.user_guid == detail.user_guid && 'text-success'"
            >
              {{ item.user_guid == detail.user_guid ? "&#10003;" : "Choose" }}
            </b-button>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filterCustomer"
          :rows="filterCustomer.rows"
          @pagination="pagination"
        />

        <!-- <b-row>
          <b-col sm="6">
            <b-button class="cancel-btn" @click="cancelMessageModel">
              Cancel
            </b-button>
          </b-col>
        </b-row>-->
      </div>
      <template #modal-footer>
        <b-button class="cancel-btn" @click="cancelMessageModel">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import FieldTextInput from "../booking/report/component/FieldTextInput.vue";
import FieldCheckbox from "../booking/report/component/FieldCheckbox.vue";
import FieldRadio from "../booking/report/component/FieldRadio.vue";
import FieldDropdown from "../booking/report/component/FieldDropdown.vue";
import FieldDateTime from "../booking/report/component/FieldDateTime.vue";
import FieldBranch from "../booking/report/component/FieldBranch.vue";
import FieldDate from "../booking/report/component/FieldDate.vue";
import FieldTextAreaInput from "../booking/report/component/FieldTextAreaInput.vue";
import UploadImage from "../booking/report/component/UploadImage.vue";
import FieldDisplay from "../booking/report/component/FieldDisplay.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    InputText,
    InputTextArea,
    FieldTextInput,
    FieldCheckbox,
    FieldRadio,
    FieldDropdown,
    FieldDateTime,
    FieldBranch,
    FieldDate,
    UploadImage,
    FieldTextAreaInput,
    FieldDisplay,
    ModalAlertError,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      fields: [],
      detail: {
        sale_user_guid: "",
        sale_branch_id: "",
        firstname_answer: "",
        lastname_answer: "",
      },
      statusCampaign: null,
      filter: {
        formId: this.$route.query.campaignID,
        id: parseInt(this.$route.params.id),
      },
      filterCustomer: {
        page: 1,
        take: 10,
        search: "",
        row: 0,
      },
      form: {
        user_id: 0,
        point: 0,
        note: "",
        telephone: "",
        message: "",
      },
      branchList: [],
      arrBranchList: [],
      staffList: [],
      checkboxIndexList: [],
      modalMessage: "",
      modalMessageShow: false,

      isLoading: true,
      dynamic_field: [],
      status_options: [],
      isEditNote: false,
      isBusy: false,
      itemsCustomer: [],
      fieldCustomer: [
        {
          key: "id",
          label: "",
        },
        {
          key: "username",
          label: "Customer Name",
          class: "text-center",
        },
        {
          key: "member_tier",
          label: "Tier",
        },
        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "email",
          label: "Email",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      disabledPoint: false,
    };
  },
  validations: {
    detail: {
      firstname_answer: {
        required,
      },
      lastname_answer: {
        required,
      },
      sale_user_guid: {
        required,
      },
      sale_branch_id: {
        required,
      },
    },
  },
  watch: {
    isLoading: {
      handler(val) {
        if (!this.isLoading) {
          setTimeout(() => {
            this.triggerCheckbox();
          }, 100);
        }
      },
    },
  },
  async created() {
    this.getBranchList();
    await this.getFormDetail();
  },
  destroyed() {},
  methods: {
    handleChangeCustomer(item) {
      this.$nextTick(() => {
        this.detail.username = item.username;
        this.detail.telephone = item.telephone;
        this.detail.email = item.email;
        this.detail.user_guid = item.user_guid;
        this.modalMessageShow = false;
        this.filterCustomer = {
          page: 1,
          take: 10,
          search: "",
        };
      });
    },
    async handleSearch() {
      const res = await this.axios.post(
        `/booking/getcustomer`,
        this.filterCustomer
      );
      this.filterCustomer.rows = res.data.detail.count;
      this.itemsCustomer = res.data.detail.reponse;
    },
    async getStatusOptions() {
      const res = await this.axios(
        `/booking/getstatus/${this.detail.booking_id}`
      );

      this.status_options = res.data.detail;
    },
    handleChangeName() {
      this.handleSearch();
      this.modalMessageShow = true;
    },
    handleReject() {
      return this.statusCampaign == 2
        ? this.submitCampaign(0)
        : this.openMessageModal();
    },
    async getFormDetail() {
      let haveSelectBranch = false;
      this.isLoading = true;
      await this.$store.dispatch("getBookingFormDetail", this.filter);
      let data = this.$store.state.form.campaignDetail;
      this.statusCampaign = 1;
      data.detail.description.map((el) => {
        if (el.field_type_id == 2) {
          if (el.value[0] == ",") {
            el.value.replace(",", "");
          }
          el.value = el.value.split(",");
        }
      });
      this.detail = data.detail;
      if (data.result == 1) {
        this.disabledPoint = data.detail.point > 0;
        if (this.detail.sale_branch_id) {
          this.getStaffList();
        }
      }
      this.getStatusOptions();
      this.isLoading = false;
    },
    async getBranchList() {
      const res = await this.axios(`/booking/get_branch_list`);

      this.branchList = res.data.detail;
    },
    handleChangeBranch() {
      document.getElementById("sale-list").value = "";
      this.detail.sale_user_guid = "";
      this.detail.sale = "";
      this.getStaffList();
    },
    async getStaffList() {
      this.staffList = [];
      const res = await this.axios(
        `/booking/salebranch/${this.detail.sale_branch_id}`
      );

      this.staffList = res.data.detail;
    },
    updateImageList(list) {
      this.detail[list.index].value = list.value;
    },
    deleteImgList(index) {
      this.detail[index].value = "";
    },
    triggerCheckbox() {
      this.checkboxIndexList.forEach((e) => {
        this.detail[e].value = this.detail[e].value.split(",");
      });
    },
    async submitCampaign(status) {
      this.detail.sale_branch_id =
        this.detail.sale_branch_id == 0 ? null : this.detail.sale_branch_id;
      this.detail.description.map((el) => (el.value = el.value.toString()));
      this.$v.detail.$touch();

      if (this.$v.detail.$error) {
        return;
      }

      this.$bus.$emit("showLoading");
      await this.$store.dispatch("updateBookingForm", this.detail);
      let data = this.$store.state.booking.respupdateBookingForm;
      if (data.result == 1) {
        this.successAlert().then(() => {
          this.$router.push(`/booking`);
        });
      } else {
        this.errorAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },

    openMessageModal() {
      this.modalMessageShow = true;
    },
    cancelMessageModel() {
      this.form.message = "";
      this.modalMessageShow = false;
    },
    submitMessageModel() {
      this.modalMessageShow = false;
      this.submitCampaign(0);
    },
    compareFieldValue(id) {
      return "";
    },
    toggleAddNote() {
      this.isEditNote = true;
      this.detail.answer_log = this.detail.answer_log
        ? this.detail.answer_log
        : [];
    },
    handleAddNote() {
      let object = {
        note: this.detail.note,
        booking_answer_id: this.id,
        sale_user_guid: this.detail.sale_user_guid,
        created_time: this.$moment(),
      };
      if (this.detail.answer_log.length > 0) {
        if (this.detail.answer_log[0].hasOwnProperty("id")) {
          this.detail.answer_log.splice(0, 0, object);
        } else {
          this.detail.answer_log[0] = object;
        }
      } else {
        this.detail.answer_log[0] = object;
      }

      this.isEditNote = false;
    },

    pagination(val) {
      this.filterCustomer.page = val;
      this.handleSearch();
    },
    handleChangeTake() {
      this.filterCustomer.page = 1;
      this.handleSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-custom > textarea:focus {
  border-color: var(--primary-color);
}

.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
::v-deep .vdatetime-input {
  border: none;
  width: 100%;
}
.approve-btn {
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.approve-btn:hover {
  color: var(--primary-color);
  background-color: #fff;
}
.cancel-btn {
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.cancel-btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

.row-gap-5 {
  row-gap: 5px;
}
.panel-log {
  ::v-deep p img {
    max-width: 100%;
    object-fit: cover;
  }
  padding: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  .writer-log {
    color: var(--theme-secondary-color);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.container-log {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;
  // max-height: 500px;
  // overflow-y: auto;
}
.log-title {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
input.search-bar {
  // box-shadow: inset 0 1px 2px hsl(0deg 0% 7% / 10%);
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.container-note {
  padding: 10px;
  // padding-right: 10px;
  background-color: var(--theme-secondary-color);
  border-radius: 5px;
  border-color: var(--primary-color);
}
</style>
